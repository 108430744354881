// const collectionStatus = Object.freeze({
//     CREATED: 0,
//     PENDING: 1,
//     ISSUES: 2, //Deprecated - use hasPickIssues instead
//     PICKED: 3,
//     FINAL: 4, // For collection in ISSUES that won't be complete
//     HANDOVER_START: 5,
//     HANDOVER_COMPLETE: 6,
//   });

const CollectionStatuses = {
  CREATED: 0,
  PENDING: 1,
  ISSUES: 2, //Deprecated - use hasPickIssues instead
  PICKED: 3,
  FINAL: 4, // For collection in ISSUES that won't be complete
  HANDOVER_START: 5,
  HANDOVER_COMPLETE: 6,

  toString: (value) => {
    switch (value) {
      case 0:
        return "Created";
      case 1:
        return "Pending";
      case 2:
        return "Issues";
      case 3:
        return "Picked";
      case 4:
        return "Final";
      case 5:
        return "Handover Start";
      case 6:
        return "Handover Complete";
      default:
        return "Unknown";
    }
  },
};

export default CollectionStatuses;
