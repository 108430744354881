import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useState } from "react";
import axios from "axios";
import CollectionStatuses from "../constants/CollectionStatuses";
import { utils, writeFileXLSX } from "xlsx";

const ReportUncollectedInvoices = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(null);
  const [auction, setAuction] = useState(null);

  const resultsQuery = useQuery(
    ["scReportUncollectedInvoices", auction],
    async () => {
      if (!auction) return { results: [], totalRows: 0 };
      const url = new URL(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/uncollectedinvoices`
      );
      url.searchParams.append("auctionId", auction.id);
      const results = await axios.get(url.toString());
      console.log(results.data);
      return results.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const auctionsQuery = useQuery(
    ["scAuctions", currentAuctionHouse],
    async () => {
      if (!currentAuctionHouse) return { results: [], totalRows: 0 };
      const url = new URL(`${process.env.REACT_APP_AOMS_API_URL}/auctions`);
      url.searchParams.append("auctionHouseId", currentAuctionHouse.id);
      url.searchParams.append("startRowIndex", 0);
      url.searchParams.append("maximumRows", 50);
      const results = await axios.get(url.toString());
      return results.data;
    },
    {
      enabled: !!currentAuctionHouse,
    }
  );

  const handleSendCollectionReminder = async (invoiceId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/invoices/${invoiceId}/collectionreminderemail`
      );
      alert("Collection reminder email sent successfully");
    } catch (err) {
      console.log("Error sending collection reminder email: ", err);
      alert("Error sending collection reminder email");
    }
  };

  const downloadXlsx = () => {
    if (resultsQuery.data && resultsQuery.data.totalRows > 0) {
      const filename = `Invoice_Collection_Report_${auction.auctionRef}_${
        auction.title
      }_${new Date().toLocaleDateString()}.xlsx`;
      const wb = utils.book_new();
      const mappedData = resultsQuery.data.results.map((result) => {
        return {
          "Invoice ID": result.id,
          "Invoice Date": new Date(result.invoiceDate).toLocaleString(),
          "Collection Status": result.collected
            ? "Collected"
            : result.collectionStatus
            ? CollectionStatuses.toString(result.collectionStatus)
            : "Not Collected",
          "Collection Deadline": new Date(
            result.collectionDeadline
          ).toLocaleString(),
          "Hammer Price": result.hammerPrice,
          "Total Lots": result.totalLots,
          "Pending Pick Order Lots": result.pendingPickOrderLots,
          "Picked Lots": result.pickedPickOrderLots,
          "User ID": result.authUserId,
          Email: result.email,
          Tel: result.tel,
        };
      });
      const ws = utils.json_to_sheet(mappedData);
      utils.book_append_sheet(wb, ws, "Uncollected Invoices");
      writeFileXLSX(wb, filename);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Uncollected Invoices</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              {auctionHousesQuery.data && (
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="auction_house">Auction House</InputLabel>
                  <Select
                    labelId="auction_house"
                    label="Auction House"
                    name="currentAuctionHouse"
                    value={currentAuctionHouse ? currentAuctionHouse.name : ""}
                    onChange={(e) => {
                      const auctionsHouse = auctionHousesQuery.data.find(
                        (el) => el.name === e.target.value
                      );
                      setCurrentAuctionHouse(auctionsHouse);
                    }}
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              {auctionsQuery.data && (
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="auction">Auction</InputLabel>
                  <Select
                    labelId="auction"
                    label="Auction"
                    name="auction"
                    value={auction ? auction.id : ""}
                    onChange={(e) => {
                      const auction = auctionsQuery.data.find(
                        (el) => el.id === e.target.value
                      );
                      setAuction(auction);
                    }}
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {auctionsQuery.data.map((auction) => {
                      return (
                        <MenuItem value={auction.id} key={auction.id}>
                          {auction.auctionRef} - {auction.title} -{" "}
                          {new Date(auction.endDate).toLocaleString()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {resultsQuery.data?.totalRows > 0 && (
              <Grid item xs={12} md={2}>
                <Button onClick={downloadXlsx} variant="contained">
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Collection Status</TableCell>
                <TableCell>Collection Deadline</TableCell>
                <TableCell>Hammer Price</TableCell>
                <TableCell>Total Lots</TableCell>
                <TableCell>Pending Pick Order Lots</TableCell>
                <TableCell>Picked Lots</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tel</TableCell>
                <TableCell>Actions</TableCell>
              </TableHead>
              <TableBody>
                {resultsQuery.data &&
                  resultsQuery.data.results.map((result) => {
                    let status = null;
                    if (!result.collected) {
                      if (result.collectionStatus) {
                        if (
                          result.collectionStatus ===
                          CollectionStatuses.HANDOVER_COMPLETE
                        ) {
                          status = "complete";
                        } else {
                          status = "pending";
                        }
                      } else {
                        status = "notcollected";
                      }
                    } else {
                      status = "complete";
                    }
                    return (
                      <TableRow
                        key={result.id}
                        style={{
                          backgroundColor:
                            status === "complete"
                              ? "orange"
                              : status === "pending"
                              ? "lightblue"
                              : "white",
                        }}
                      >
                        <TableCell>
                          <a
                            href={`https://portal.auctionoms.com/accounts/invoices/${result.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {result.id}
                          </a>
                        </TableCell>
                        <TableCell>
                          {new Date(result.invoiceDate).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {result.collected
                            ? "Collected"
                            : result.collectionStatus
                            ? CollectionStatuses.toString(
                                result.collectionStatus
                              )
                            : "Not Collected"}
                        </TableCell>
                        <TableCell>
                          {new Date(result.collectionDeadline).toLocaleString()}
                        </TableCell>
                        <TableCell>{result.hammerPrice}</TableCell>
                        <TableCell>{result.totalLots}</TableCell>
                        <TableCell>{result.pendingPickOrderLots}</TableCell>
                        <TableCell>{result.pickedPickOrderLots}</TableCell>
                        <TableCell>
                          <a
                            href={`/users/${result.authUserId}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {result.authUserId}
                          </a>
                        </TableCell>
                        <TableCell>{result.email}</TableCell>
                        <TableCell>{result.tel}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              handleSendCollectionReminder(result.id)
                            }
                            variant="contained"
                            size="small"
                          >
                            Send Reminder
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}></Box>
        <Typography variant="h6">Status Key</Typography>
        <Box display="flex" alignItems="center" mt={1}>
          <Box
            width={20}
            height={20}
            bgcolor="orange"
            mr={1}
            borderRadius="50%"
          />
          <Typography>Complete</Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <Box
            width={20}
            height={20}
            bgcolor="lightblue"
            mr={1}
            borderRadius="50%"
          />
          <Typography>Pending</Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <Box
            width={20}
            height={20}
            bgcolor="white"
            mr={1}
            border="1px solid black"
            borderRadius="50%"
          />
          <Typography>Not Collected</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportUncollectedInvoices;
